* {
  box-sizing: border-box;
}

html {
	position: relative;
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font-family-main;
}

body {
	position: relative;
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font-family-main;
  margin: 0;
  padding: 0;
}

#wrapper {
	position: relative;
	overflow-x: hidden;
}

.row > * {
  padding-right: 15px;
  padding-left: 15px;
}

h1, h2, h3, h4, h5, h6 {
	font-family: $font-family-main;
  color: #20264C;
}

h1 {
  font-size: 60px;
  line-height: 80px;
  font-weight: 700;
}

h2 {
	font-size: 32px;
	line-height: 36px;
  font-weight: 700;

  @media screen and (max-width: 600px) {
    font-size: 30px;
    line-height: 36px;
  }
}

h3 {
  font-size: 21px;
  line-height: 26px;
  font-weight: 700;
}

p {
	font-family: $font-family-main;
	font-size: 20px;
	line-height: 27px;
	color: #333333;
	font-weight: 400;
  letter-spacing: 0;

	span {
		font-weight: 700;
	}

  @media screen and (max-width: 600px) {
    font-size: 18px;
    line-height: 24px;
  }
}

.subtitle {
  color: $main-color;
  font-family: $font-family-main;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.color {
  color: $main-color;
}

main {
  ul {
    li {
      font-family: $font-family-main;
      font-size: 14px;
      line-height: 32px;
      color: #0C0C0C;
      font-weight: 300;

      span {
        font-weight: 700;
      }
    }
  }
}

a {
	outline: none !important;
	text-decoration: none;
  color: #000;

  &:visited {
    color: inherit;
  }

  &:hover {
    color: inherit;
  }
}

.container {
  max-width: 1610px;
  margin: 0 auto;
  width: 100%;

  @media screen and (max-width: 1650px) {
      max-width: 1430px;
      padding: 0 20px;
  }

  @media screen and (max-width: 1400px) {
      max-width: 1170px;
  }

  @media screen and (max-width: 1199px) {
      max-width: 960px;
  }

  @media screen and (max-width: 991px) {
      max-width: 720px;
  }

  @media screen and (max-width: 768px) {
      max-width: 540px;
  }

  &--large {
    max-width: 1610px;
    margin: 0 auto;

    @media screen and (max-width: 1650px) {
        max-width: 1430px;
    }

    @media screen and (max-width: 1400px) {
        max-width: 1170px;
    }

    @media screen and (max-width: 1199px) {
        max-width: 960px;
    }

    @media screen and (max-width: 991px) {
        max-width: 720px;
    }

    @media screen and (max-width: 768px) {
        max-width: 540px;
    }
  }
}

.button-group {
  max-width: 325px;
  margin: 0 auto;

  .button {
    width: 100%;
    margin-top: 14px;

    &:first-child {
      margin-top: 0;
    }
  }
}

.button {
  display: inline-block;
  padding: 13px 32px 14px;
  color: #fff;
  background: $main-color;
  font-family: $font-family-main;
  font-size: 19px;
  font-weight: 700;
  border: 5px solid $main-color;
  border-radius: 50px;
  transition: all .25s ease;
  cursor: pointer;

  &:visited {
    color: #fff;
  }

  &:hover {
    background: #fff;
    color: $alt-color;
  }
}

.text-center {
	text-align: center;
}

.text-right {
  text-align: right;
}

.align-bottom {
  align-items: flex-end;
}

.align-center {
  align-items: center;
}
