/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * kiro:
 *   - http://typekit.com/eulas/00000000000000007735c3dd
 *   - http://typekit.com/eulas/00000000000000007735c3df
 *   - http://typekit.com/eulas/00000000000000007735c3e0
 *   - http://typekit.com/eulas/00000000000000007735c3e1
 *   - http://typekit.com/eulas/00000000000000007735c3e2
 *
 * © 2009-2022 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2022-03-16 10:35:26 UTC"}*/

@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;600;700;800&display=swap');

$main-color: #FFC702;
$alt-color: #17ADD1;

$font-family-main: 'Be Vietnam Pro', sans-serif;
