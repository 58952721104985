#wrapper {
	position: relative;

	.s1 {
		background: url(../img/dentalbound-masthead.jpg);
		background-size: cover;
		background-position: 50% 0%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 100%;
		height: 120vh;
		position: relative;

		@media screen and (max-width: 991px) {
			height: 100vh;
			background-position: 20% 50%;
			align-items: flex-end;
		}

		@media screen and (max-width: 768px) {
			height: unset;
			padding: 300px 0;
			margin-bottom: 350px;
		}

		@media screen and (max-width: 550px) {
			padding: 350px 0;
			background-position: 30% 40%;
			margin-bottom: 400px;
		}

		&__bg {
			position: absolute;
			top: -156px;
			right: -120px;
			max-height: calc(120vh + 156px);
			overflow: hidden;

			@media screen and (max-width: 1650px) {
		    	right: -310px;
		    	width: 73%;
		  	}

		  	@media screen and (max-width: 1400px) {
				width: 65%;
			}
		}

		.container {
			display: flex;
			flex-direction: column;
			align-items: flex-end;

			@media screen and (max-width: 768px) {
				max-width: unset;
				padding: 0;
			}
		}

		&__text {
			position: relative;
			z-index: 1;
			background: #1192B1;
			padding: 3px 35px 50px;
			text-align: center;
			width: 616px;
			max-width: 616px;
			border-radius: 12px;
			margin-right: 140px;
			margin-top: 50px;

			@media screen and (max-width: 1650px) {
		    	margin-right: 50px;
		    	margin-top: 20px;
		  	}

		  	@media screen and (max-width: 1400px) {
				margin-right: 0;
				max-width: 550px;
				width: 100%;
			}

			@media screen and (max-width: 1199px) {
				max-width: 450px;
			}

			@media screen and (max-width: 991px) {
				max-width: unset;
				margin: 0 auto 150px;
			}

			@media screen and (max-width: 768px) {
				position: absolute;
				left: 0;
				bottom: -50%;
				max-width: unset;
				border-radius: 0;
				margin: 300px auto 30px;
				padding: 3px 35px 80px;
			}

			h1 {
				font-size: 52px;
				line-height: 56px;
				color: #FFF;
				font-weight: 800;
				margin-bottom: 0;

				@media screen and (max-width: 1400px) {
				    font-size: 42px;
				    line-height: 48px;
				}

				@media screen and (max-width: 1199px) {
					font-size: 35px;
				    line-height: 42px;
				}
			}

			p {
				font-size: 22px;
				line-height: 32px;
				color: #FFF;
				text-transform: uppercase;
				font-weight: 300;
				margin-bottom: 34px;

				@media screen and (max-width: 1400px) {
				    font-size: 20px;
					line-height: 30px;
				}
			}
		}

		.float-button {
			position: absolute;
			right: -101px;
			top: 51%;
			transform: rotate(90deg);
			z-index: 1;

			@media screen and (max-width: 1650px) {
		    	top: 49%;
		  	}

		  	@media screen and (max-width: 1199px) {
		    	top: 47.5%;
		  	}

		  	@media screen and (max-width: 991px) {
		    	top: 62%;
		  	}

		  	@media screen and (max-width: 768px) {
		    	top: 48%;
		  	}

		  	@media screen and (max-width: 550px) {
		    	top: 36%;
		  	}

			.button {
				border-radius: 0;
				font-weight: 600;
				font-size: 15px;
				line-height: 21px;
				text-transform: uppercase;
				padding: 10px 30px 11px;
				cursor: pointer;

				&:hover {
					background: $alt-color;
					color: #fff;
				}
			}
		}
	}

	.s2 {
		background: #EAAE37;
		position: relative;
		margin-top: -27px;
		padding: 0 0 90px;
		position: relative;
		z-index: 1;

		&::before {
			content: '';
			position: absolute;
			top: -90px;
			left: -20px;
			width: 102%;
			height: 250px;
			transform: rotate(3deg);
			background: #EAAE37;
			z-index: -1;

			@media screen and (max-width: 991px) {
				width: 108%;
			}
		}

		h2 {
			font-size: 45px;
			line-height: 49px;
			font-weight: 800;
			color: #FFF;
			padding-top: 17px;
			margin-bottom: 27px;
			padding-left: 130px;

			@media screen and (max-width: 1400px) {
		    	padding-left: 0;
		  	}

		  	@media screen and (max-width: 1199px) {
				font-size: 35px;
			    line-height: 42px;
			}
		}

		&__text {
			display: flex;
			padding-left: 130px;

			@media screen and (max-width: 1400px) {
		    	padding-left: 0;
		  	}

		  	@media screen and (max-width: 991px) {
		    	flex-wrap: wrap;
		  	}

			.col-50 {
				width: 50%;

				@media screen and (max-width: 991px) {
			    	width: 100%;
			  	}

				&:first-of-type {
				    padding-right: 107px;

				    @media screen and (max-width: 1400px) {
				    	padding-right: 30px;
				  	}

				  	@media screen and (max-width: 991px) {
				    	padding-right: 0px;
				  	}
				}

				&:last-of-type {
					@media screen and (max-width: 1400px) {
				    	padding-left: 65px;
				  	}

				  	@media screen and (max-width: 991px) {
				    	padding-left: 0px;
				  	}

					p {
						margin-right: 31px;

						@media screen and (max-width: 1400px) {
					    	margin-right: 0;
					  	}
					}
				}

				p {
					color: #fff;
					margin-bottom: 27px;
				}

				h3 {
					color: #1192B1;
					font-size: 28px;
					line-height: 33px;
					font-weight: 700;
					margin-bottom: 35px;
				}
			}
		}

		.btn-wrap {
			margin-top: 53px;

			.button {
				padding: 13px 12px 14px;
				cursor: pointer;

				span {
					font-style: italic;
				}
			}
		}
	}

	.s3 {
		position: relative;
		padding: 180px 0 120px;
		overflow: hidden;

		@media screen and (max-width: 991px) {
	    	padding: 110px 0 110px;
	  	}

		&__bg {
			position: absolute;
			top: -80px;
			left: -155px;
			z-index: -1;

			@media screen and (max-width: 991px) {
		    	left: -710px;
		    	top: -105px;
		  	}
		}

		&__bg2 {
			position: absolute;
			bottom: -140px;
			right: -460px;
			z-index: -1;

			@media screen and (max-width: 991px) {
		    	right: -725px;
		  	}
		}

		&__content {
			display: flex;

			@media screen and (max-width: 991px) {
		    	flex-wrap: wrap;
		  	}

			.col-50 {
				width: 50%;

				@media screen and (max-width: 991px) {
			    	width: 100%;
			  	}

				&:last-of-type {
					padding-left: 65px;

					@media screen and (max-width: 991px) {
				    	padding-left: 0;
				    	margin-top: 30px;
				  	}
				}

				.photo {
					img {
						max-width: 100%;
					}
				}

				.text {
					h2 {
						font-size: 45px;
						line-height: 52px;
						font-weight: 800;
						color: $alt-color;
						margin-top: -4px;
    					margin-bottom: 29px;

    					@media screen and (max-width: 1199px) {
							font-size: 35px;
						    line-height: 42px;
						}
					}

					h3 {
						color: #CC972F;
						font-size: 34px;
						line-height: 40px;
						font-weight: 800;
						margin: -4px 0 -4px;

						@media screen and (max-width: 1199px) {
							font-size: 28px;
						    line-height: 35px;
						}

						&.margin {
							margin: 38px 0 23px;
						}
					}

					p {
						margin-bottom: 25px;
					}

					.button {
						padding: 13px 6px 14px;

						@media screen and (max-width: 1650px) {
					    	padding: 13px 36px 14px;
					    	text-align: center;
					  	}
					}

					ul {
						list-style: none;
						padding: 0 0 0 20px;
						margin: 0;

						li {
							font-family: $font-family-main;
							font-size: 20px;
							line-height: 27px;
							color: #333333;
							font-weight: 400;
						  	letter-spacing: 0;
						  	position: relative;
						  	margin-bottom: 21px;
						  	max-width: 575px;

						  	@media screen and (max-width: 600px) {
							    font-size: 18px;
							    line-height: 24px;
							  }

						  	&::before {
						  		content: '';
						  		position: absolute;
						  		left: -20px;
						  		top: 11px;
						  		background: #C4E2ED;
						  		width: 7px;
						  		height: 7px;
						  		border-radius: 50%;
						  	}
						}
					}
				}
			}

			&--v2 {
				margin-top: 115px;

				@media screen and (max-width: 991px) {
			    	margin-top: 50px;
			  	}

				.col-50 {
					&:first-of-type {
						@media screen and (max-width: 991px) {
					    	order: 2;
					    	margin-top: 30px;
					  	}
					}

					.text {
						padding-left: 130px;

						@media screen and (max-width: 1400px) {
					    	padding-left: 0;
					  	}
					}
				}
			}
		}
	}

	.s4 {
		position: relative;
		background: #1192B1;
		padding: 52px 0 82px;

		&__text {
			display: flex;
			padding-left: 130px;

			@media screen and (max-width: 1400px) {
		    	padding-left: 0;
		  	}

		  	@media screen and (max-width: 991px) {
		    	flex-wrap: wrap;
		  	}

			.col-50 {
				width: 50%;

				@media screen and (max-width: 991px) {
			    	width: 100%;
			  	}

				&:first-of-type {
				    padding-right: 107px;

				    @media screen and (max-width: 1400px) {
				    	padding-right: 25px;
				  	}

				  	@media screen and (max-width: 991px) {
				    	padding-right: 0;
				  	}
				}

				&:last-of-type {

					p {
						margin-right: 20px;

						@media screen and (max-width: 991px) {
					    	margin-right: 0;
					  	}
					}
				}

				&.col-right {
					margin-top: 9px;

					@media screen and (max-width: 1400px) {
				    	padding-left: 66px;
				  	}

				  	@media screen and (max-width: 991px) {
				    	padding-left: 0;
				    	margin-top: 30px;
				  	}
				}

				h2 {
					font-size: 45px;
					line-height: 50px;
					color: #FFF;
					font-weight: 800;
					margin-bottom: 34px;

					@media screen and (max-width: 1199px) {
						font-size: 35px;
					    line-height: 42px;
					}
				}

				p {
					color: #fff;
					margin-bottom: 27px;
				}

				.big {
					font-size: 28px;
					line-height: 37px;
					margin-bottom: 32px;

					@media screen and (max-width: 1199px) {
						font-size: 23px;
					    line-height: 32px;
					}
				}

				.italic {
					font-style: italic;
					font-weight: 700;

					span {
						font-style: normal;
						font-weight: 400;
					}
				}

				h3 {
					color: #1192B1;
					font-size: 28px;
					line-height: 33px;
					font-weight: 700;
					margin-bottom: 35px;

					@media screen and (max-width: 1199px) {
						font-size: 23px;
					    line-height: 32px;
					}
				}

				.button {
					margin-top: 15px;
					padding: 15px 39px 16px;

					@media screen and (max-width: 1650px) {
				    	padding: 15px 6px 16px;
				  	}

				  	@media screen and (max-width: 1400px) {
				    	padding: 15px 36px 16px;
				    	text-align: center;
				  	}
				}
			}
		}
	}

	.s5 {
		position: relative;
		padding: 64px 0 64px;
		text-align: center;
	}
}

/* Tabs Start */

.tabs {
	position: relative;
	margin-bottom: 50px;

	>input,
	>span {
		width: 309px;
		height: 60px;
		position: absolute;
		top: 0;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;

		@media screen and (max-width: 768px) {
			width: 49%;
		}
	}

	> input {
		cursor: pointer;
		filter: alpha(opacity=0);
		opacity: 0;
		position: absolute;
		z-index: 99;

		&:checked,
		&:hover {
			+ span {
				background: #1192B1;
				color: $main-color;
			}
		}
		&:disabled {
			cursor: default;

			&:hover {
				+span {
					background: #fff;
					color: #1192B1;
				}
			}
		}
	}
	> span {
		background: #ffffff;
		text-align: center;
		color: #1192B1;
		font-weight: bold;
		font-size: 18px;
		letter-spacing: -0.02em;
		-webkit-transition: all .5s;
		-moz-transition: all .5s;
		-o-transition: all .5s;
		transition: all .5s;
	}

	.tab-content {
		padding: 60px 0 0;
		width: 100%;
		min-height: 340px;

		section {
			width: 100%;
			display: none;

			h2 {
				font-size: 28px;
				line-height: 33px;
				color: #1192B1;
				font-weight: bold;
				text-align: center;
				margin: 0 0 20px;

				@media screen and (max-width: 768px) {
					font-size: 22px;
					line-height: 25px;
				}
			}
		}

		&__inner {
			border-top: 3px solid #1192B1;
			padding: 43px 76px 20px;
			background: #f1f8fB;

			@media screen and (max-width: 768px) {
				padding: 20px 20px 20px;
			}
		}

	}
	.checkbox-group {
		text-align: left;

		&__legend {
			font-size: 28px;
			line-height: 33px;
			color: #1192B1;
			font-weight: bold;

			@media screen and (max-width: 768px) {
				font-size: 22px;
				line-height: 25px;
			}

			span {
				font-size: 18px;
				color: #333333;
				font-weight: 300;

				@media screen and (max-width: 768px) {
					font-size: 14px;
					line-height: 14px;
				}
			}
		}
		&__items {
			margin: 13px 0 43px;

			div {
				display: flex;
				align-items: center;
				margin: 5px 0 6px 6px;
			}
		}

		input[type="checkbox"]{
			cursor: pointer;
			border: 0;
			appearance: none; -webkit-appearance: none;
			margin: 0;
			cursor: pointer;
			position: relative;

			&:before {
				content: '';
				width: 16px;
				height: 16px;
				border: 1px solid #707070;
				display: block;
				margin-right: 18px;
			}
			&:after {
				content: '';
				font-size: 0;
				color: #707070;
				position: absolute;
				left: 2px;
				top: 2px;
				width: 0;
				height: 0;
				background: $alt-color;
			}
			&:checked {
				&:after {
					width: 14px;
					height: 14px;
				}
			}
			+ label {
				&:hover {
					cursor: pointer;
					color: $alt-color;
				}
			}
		}

		&.second {
			max-width: 617px;
			margin: 0 auto;

			.button {
				margin-top: 50px;
			}
		}
	}
	#tab-1, #tab-1 + span { left: 0; }
	#tab-2, #tab-2 + span {
		left: 315px;

		@media screen and (max-width: 768px) {
			left: 51%;
		}
	}

	#tab-1:checked ~ .tab-content #tab-item-1 { display: block; }
	#tab-2:checked ~ .tab-content #tab-item-2 { display: block; }

	.first-step-error {
		font-size: 26px;
		line-height: 50px;
		color: #F05353;
		margin-bottom: 30px;
		display: none;

		@media screen and (max-width: 768px) {
			font-size: 22px;
			line-height: 46px;
		}

		@media screen and (max-width: 580px) {
			font-size: 15px;
			line-height: 25px;
			margin-bottom: 20px;
		}

		&:before {
			content: '';
			width: 50px;
			height: 50px;
			margin-right: 25px;
			background: url('../../img/warning-icon.svg') 0 0 no-repeat;
			background-size: contain;

			@media screen and (max-width: 768px) {
				width: 40px;
				height: 40px;
			}
			@media screen and (max-width: 580px) {
				width: 30px;
				height: 30px;
				margin-right: 15px;
			}
		}

		@media screen and (max-width: 768px) {
			font-size: 20px;
		}

		&.showed {
			display: inline-flex;
		}
	}
}




/* Modal */
.modal {
  position: relative;
  z-index: 100;

  	&__overlay {
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  position: fixed;
	  top: 0;
	  right: 0;
	  bottom: 0;
	  left: 0;
	}

	&__container {
	  max-width: 100%;
	  width: 1050px;
	  max-height: 100vh;
	  background-color: #E0F0F6;
	  overflow-y: auto;
	  border-radius: 12px;
	  padding-bottom: 15px;

		&::-webkit-scrollbar {
			width: 7px;
			background-color: #F5F5F5;

			&:horizontal {
				height: 7px;
			}
		}
		&::-webkit-scrollbar-thumb {
			background-color: #aaa;
			border-radius: 7px;
		}
		&::-webkit-scrollbar-track {
			border-radius: 7px;
			background-color: #F5F8FB;
		}

		@media screen and (max-width: 1050px) {
			width: 100%;
		}
	}

	&__header {
	  display: flex;
	  justify-content: flex-end;
	  align-items: center;
	  padding: 34px 31px 0 0;
	}

	&__content {
	  margin: 15px 0 54px;
	  text-align: center;
	  padding: 0 54px;

	  @media screen and (max-width: 550px) {
    	padding: 0 20px;
  	  }

	  .title {
	  	font-size: 28px;
	  	line-height: 33px;
	  	color: #1192B1;
	  	font-weight: 700;
	  	text-align: center;
		letter-spacing: -0.03em;
	  }

	  .popup-form {
	  	margin-top: 55px;

	  	input {
			&[type="email"],
			&[type="text"] {
				background: #fff;
				border: 1px solid #1192B1;
				font-size: 18px;
				line-height: 65px;
				font-weight: 300;
				color: #1192B1;
				padding: 0 20px;
				width: 100%;
				margin-bottom: 10px;

				@media screen and (max-width: 768px) {
					line-height: 50px;
				}
			}
			
			&:focus {
				outline: none;
			}

			&::placeholder {
				color: #1192B1;
				font-weight: 300;
			}

			&.error {
				border: 4px solid #F05353;
			}
		}

		.button {
			font-size: 23px;
			line-height: 58px;
			text-align: center;
			margin: 50px 0 30px;
			cursor: pointer;
			background: #fff;
			color: $alt-color;
			padding: 0 40px;

			@media screen and (max-width: 768px) {
				padding: 0 20px;
				font-size: 14px;
				line-height: 58px;
			}

			&.next {
				margin: 10px 0 30px;

				@media screen and (max-width: 768px) {
					padding: 0 40px;
				}
			}

			&:hover {
				background: $main-color;
				color: #fff;
			}
		}
		#success-message,
		#error-message {
		  display: none;
		  font-size: 13px;
	      font-weight: 400;
	      color: #1192B1;
		}
		.success-message-container {
			&__title {
				font-size: 28px;
				font-weight: bold;
				color: #1192B1;
				margin-bottom: 22px;
			}
			&__sub-title {
				font-size: 98px;
				line-height: 78px;
				color: $main-color;
				margin-bottom: 25px;
				font-weight: bold;

				@media screen and (max-width: 768px) {
					font-size: 68px;
					line-height: 68px;
				}
			}
			&__description {
				font-size: 28px;
				line-height: 33px;
				color: #1192B1;
				text-align: center;
				font-weight: bold;
				max-width: 840px;
				margin: 40px auto 30px;

				@media screen and (max-width: 580px) {
					font-size: 24px;
					line-height: 29px;
					margin: 40px auto 0;
				}
			}
			&__video-container {
				margin: 20px 0;
				position: relative;

				.video-cover {
					width: 100%;
					height: auto;
					position: absolute;
					left: 0;
					top: 0;
					z-index: 5;
				}

				.play-btn {
					width: 206px;
					height: 206px;
					border-radius: 100%;
					background: #fff;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					z-index: 6;

					@media screen and (max-width: 768px) {
						width: 100px;
						height: 100px;
					}
					@media screen and (max-width: 580px) {
						width: 60px;
						height: 60px;
					}

					&:before {
						content: '';
						background: url('../../img/play-btn.svg') 0 0 no-repeat;
						background-size: cover;
						width: 100%;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
					}

					&:hover {
						cursor: pointer;
						background: $alt-color;
					}
				}
			}
		}
	  }
	}

	&__close {
		cursor: pointer;
		background: none;
		border: 0;
		border-radius: 0;

		&:focus {
			outline: none;
		}
	}
}

.play-video-container {
	padding: 56.25% 0 0 0;
	position: relative;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	+.play-video-container {
		margin-top: 50px;
	}
}

/* Animation */
@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(10%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}

.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
  animation: mmfadeIn 0.5s cubic-bezier(0.34, 0.07, 0.095, 0.995);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
  animation: mmslideIn 0.5s cubic-bezier(0.34, 0.07, 0.095, 0.995);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
  animation: mmfadeOut 0.5s cubic-bezier(0.34, 0.07, 0.095, 0.995);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
  animation: mmslideOut 0.5s cubic-bezier(0.34, 0.07, 0.095, 0.995);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}
